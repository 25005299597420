
import moment from 'moment'
import Vue, { PropType } from 'vue'
import api from '../../api/api'

import Breadcrumbs from '../../components/Breadcrumbs.vue'
import ClientIconsMenu from '../../components/iconLinks/ClientIconsMenu.vue'
import SpinnerLoader from '../../components/SpinnerLoader.vue'
import { Connector, Project } from '../../types/main'
import { CONNECTOR_TYPES } from '../../vars/general'
import BaseInputOutlined from '../../components/inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../../components/inputs/CustomActivatorSelect.vue'
import _ from 'lodash'
import { numSort, floatNumSort } from '../../utils'
export default Vue.extend({
    name: 'GCB2PersonaReport',
    components: {
        Breadcrumbs,
        SpinnerLoader,
        ClientIconsMenu,
        CustomActivatorSelect,
        BaseInputOutlined,
    },

    data: () => ({
        pageLoading: false,
        dashboardDataLoading: false,
        dateMenu: false,
        dates: [] as string[],
        dateFormat: 'DD.MM.YYYY',
        datePickerDateFormat: 'YYYY-MM-DD',
        dateHasChanged: false,
        filters: {} as any,
        filtersLists: {} as any,
        inititalFilters: {},
        dashboardData: {
            statByStaff: [] as any[],
        },
        tableHeaders: [
            { text: 'ФИО', value: 'staff_name' },
            { text: 'Общая выручка', value: 'revenue' },
            { text: 'Кол-во смен', value: 'shift_count' },
            { text: 'Средний чек', value: 'avg_check' },
            { text: 'Доля постоянных', value: 'regular', isPercent: true },
        ],
    }),
    watch: {},
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },

        connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        breadcrumbs(): Array<any> {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'Отчет Персона',
                    to: '',
                    disabled: true,
                },
            ]
        },
        dateRangeText(): string {
            const formatedDates = this.sortedDates.map(date =>
                moment(date, this.datePickerDateFormat).format(this.dateFormat)
            )
            return formatedDates.join(' - ')
        },
        sortedDates(): string[] {
            return [
                ...this.dates.sort(
                    (a, b) =>
                        moment(a, this.datePickerDateFormat).unix() -
                        moment(b, this.datePickerDateFormat).unix()
                ),
            ]
        },
        dateBorders(): { to: string; from: string } {
            return {
                from: this.sortedDates[0],
                to: this.sortedDates[1],
            }
        },
        displayFilters(): any {
            return {
                specializationList: Object.values(this.filters.specializationList ?? {})
                    .filter((el: any) => el.selected)
                    .map((el: any) => el.id),
                filialList: Object.values(this.filters.filialList ?? {})
                    .filter((el: any) => el.selected)
                    .map((el: any) => el.id),
            }
        },
        queryFilters(): any {
            return _.pickBy(
                {
                    dateFrom: this.dateHasChanged ? this.sortedDates[0] : undefined,
                    dateTo: this.dateHasChanged ? this.sortedDates[1] : undefined,
                    specializationList: this.filters.specializationList,
                    filialList: this.filters.filialList,
                },
                (v, k) => {
                    if (_.isObject(v) && _.isEmpty(v)) {
                        return false
                    }
                    if (
                        !_.isNil(v) &&
                        this.displayFilters[k] &&
                        (this.filtersLists[k].length === this.displayFilters[k].length ||
                            this.displayFilters[k].length === 0)
                    ) {
                        return false
                    }
                    return true
                }
            )
        },
    },
    methods: {
        onDateChange() {
            this.dateHasChanged = true
        },
        setFiltersLists() {
            this.filtersLists = {
                filialList: Object.values(this.filters.filialList ?? {}).map((el: any) => ({
                    id: el.id,
                    name: el.option,
                })),
                specializationList: Object.values(this.filters.specializationList ?? {}).map((el: any) => ({
                    id: el.id,
                    name: el.option,
                })),
            }
        },
        formatValue(val: any, isPercent?: boolean) {
            if (_.isString(val)) return val
            if (isPercent) return Math.floor(val * 100) + '%'
            return new Intl.NumberFormat('ru-RU').format(val).replaceAll(',', '.')
        },

        dropFilters() {
            this.filters = Object.assign({}, this.inititalFilters)
            this.initData()
        },
        isObject: _.isObject,
        isNil: _.isNil,
        setFilterValue(filterId: string, val: any) {
            const updatedFilterVal = {}
            const selectedHmap = val.reduce((a: any, c: any) => Object.assign(a, { [c]: true }), {})
            this.filtersLists[filterId].forEach((el: any) => {
                _.setWith(
                    updatedFilterVal,
                    [el.id],
                    {
                        id: el.id,
                        option: el.name,
                        selected: Boolean(selectedHmap[el.id]),
                        service_group: el.service_group,
                    },
                    Object
                )
            })
            this.filters = Object.assign({}, this.filters, { [filterId]: updatedFilterVal })
        },
        setDates() {
            this.dates = [this.filters.dateFrom, this.filters.dateTo]
        },
        async initData(refresh?: boolean) {
            this.dashboardDataLoading = true
            const { data, error } = await api.gcb2.getPersonaReportData({
                projectId: this.project.id,
                filters: this.queryFilters,
                refresh,
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Ошибка')
            }
            this.dashboardData = data.reportData
            this.filters = Object.assign({}, this.filters, data.filters)
            this.setFiltersLists()
            this.setDates()
            this.dateHasChanged = false
            this.dashboardDataLoading = false
        },
    },
    async mounted() {
        this.inititalFilters = Object.assign({}, this.filters)
        this.pageLoading = true

        await this.initData()
        this.pageLoading = false
    },
})
